import { Button } from '@chakra-ui/react'
import { useSetTrustedUser } from './services/useSetTrustedUser'
import { useSetUntrustedUser } from './services/useSetUntrustedUser'

// не стал удалять модалку, в будущем может пригодится

export const TrustUser = ({ userId, trusted, isDisabled }) => {
  const { mutate: setTrusted, isLoading: trustedIsLoading } = useSetTrustedUser(userId)
  const { mutate: setUntrusted, isLoading: untrustedIsLoading } = useSetUntrustedUser(userId)
  // const [isOpen, setOpenModal] = useBoolean()
  // const [comment, setComment] = useState()

  const handleSubmit = () => {
    if (trusted) {
      setUntrusted()
    } else {
      setTrusted()
    }

    // setOpenModal.off()
  }

  const isLoading = trustedIsLoading || untrustedIsLoading

  return (
    <>
      <Button
        width="100%"
        onClick={handleSubmit}
        colorScheme={!trusted ? 'green' : 'orange'}
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        {!trusted ? 'Добавить в белый список' : 'Убрать из белого списка'}
      </Button>
      {/* <Modal isOpen={isOpen} onClose={setOpenModal.off} title="Комментарий">
        <Textarea
          value={trusted}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Введите комментарий"
        />
        <Box pt="30px" pb="20px">
          <Button
            colorScheme="orange"
            isLoading={isLoading}
            onClick={handleSubmit}
            isDisabled={comment === ''}
          >
            Подтвердить
          </Button>
        </Box>
      </Modal> */}
    </>
  )
}
