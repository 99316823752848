import React, { useCallback } from 'react'
import { Box, Button, HStack, Icon, Text, Tooltip } from '@chakra-ui/react'
import { useFetchUserBalance } from '../hooks/useFetchUserBalance'
import { useFetchStats } from '../hooks/useFetchStats'
import { InfoIcon } from '@chakra-ui/icons'
import { getDateTimeToSubmit } from 'utils/dateFormatting'

export const PaymentsActions = ({ user, filters }) => {
  const [fetchUserBalance, { isLoading: userBalanceIsLoading, data: balance }] =
    useFetchUserBalance(user.id)

  const { mutate: fetchStats, data: stats, isLoading: statsIsLoading } = useFetchStats()

  const onShowBalance = useCallback(() => {
    fetchUserBalance()
  }, [])

  const onUpdateStats = useCallback(() => {
    const { paymentDateFrom, paymentDateTo, paymentState } = filters

    fetchStats({
      payerPhone: user.phone,
      paymentDateFrom: paymentDateFrom ? getDateTimeToSubmit(paymentDateFrom) : undefined,
      paymentDateTo: paymentDateTo ? getDateTimeToSubmit(paymentDateTo) : undefined,
      paymentState: paymentState !== 'all' || !paymentState ? paymentState : undefined,
    })
  }, [filters])

  const actionStatsIsEnabled = filters?.paymentState

  return (
    <>
      <Box width="full" mb="20px">
        <HStack spacing={1} alignItems="baseline">
          <Text fontSize="18px" mt={2}>
            Статистика
          </Text>
          <Tooltip label="Для подсчета ежемесячных лимитов платежей необходимо выбрать диапазон дат с первого по последнее число месяца и статус платежа (Проведен)">
            <Icon cursor="pointer" as={InfoIcon} color="orange.300" mb="20px" />
          </Tooltip>
        </HStack>
        <Box>
          <Text fontSize="13px">{`Платежей найдено: ${
            stats?.numberOfPaymentsFound ?? ' - '
          }`}</Text>
          <Text fontSize="13px">{`Сумма платежей: ${
            stats?.paymentsTotalRub ? stats?.paymentsTotalRub + ' руб' : ' - '
          }`}</Text>
          <Text fontSize="13px">{`Сумма комиссий: ${
            stats?.commissionsTotalRub ? stats?.commissionsTotalRub + ' руб' : ' - '
          }`}</Text>
          <Text fontSize="13px">{`Сумма платежей c учетом комиссий: ${
            stats?.paymentsAndCommissionsTotalRub
              ? stats?.paymentsAndCommissionsTotalRub + ' руб'
              : ' - '
          }`}</Text>
        </Box>
        <Button
          isDisabled={!actionStatsIsEnabled}
          isLoading={statsIsLoading}
          mt="15px"
          size="sm"
          width="100%"
          onClick={onUpdateStats}
        >
          {!stats ? 'Получить' : 'Обновить'}
        </Button>
      </Box>
      <Box mb="30px">
        <Box fontSize="14px" mb="10px">
          <Box>
            <Text as="span">Текущий остаток на счете: </Text>
            <Text as="span" fontWeight="bold">
              {balance?.saldo ?? ' - '}
            </Text>
          </Box>
          <Box>
            <Text as="span">Свободные средства без учета резерва: </Text>
            <Text as="span" fontWeight="bold">
              {balance?.freeNoShadow ?? ' - '}
            </Text>
          </Box>
        </Box>
        <Button
          colorScheme="orange"
          size="sm"
          onClick={onShowBalance}
          isLoading={userBalanceIsLoading}
        >
          Показать баланс
        </Button>
      </Box>
    </>
  )
}
