import React from 'react'
import { Button } from '@chakra-ui/react'
import useDisableUser from './services/useDisableUser'
import { getUserItem } from 'store/user.reducer'
import { useDispatch } from 'react-redux'

export const DisactivateUser = ({ userId }) => {
  const dispatch = useDispatch()
  const { setDisable, isLoading } = useDisableUser(userId)

  const handleSubmit = async () => {
    await setDisable()
    await dispatch(getUserItem(userId))
  }

  return (
    <Button
      width="100%"
      onClick={handleSubmit}
      colorScheme="orange"
      isLoading={isLoading}
    >
      Деактивировать
    </Button>
  )
}
