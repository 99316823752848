import { keycloak } from './keycloak'
import { users } from './users'
import { payments } from './payments'
import { scans } from './scans'
import { luna } from './luna'
import { identifications } from './identifications'
import { ekeng } from './ekeng'
import { documents } from './documents'
import { referral } from './referral'
import { banner } from './banner'
import { settings } from './settings'
import { journal } from './journal'
import { config } from './config'
import { blackList } from './blackLlst'

export const api = {
  users,
  payments,
  keycloak,
  scans,
  luna,
  identifications,
  ekeng,
  documents,
  referral,
  banner,
  settings,
  journal,
  config,
  blackList
}
