import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Login } from '../Login'
import { useSelector } from 'react-redux'
import { SectionMenu, SectionMenuItem } from 'chakra-lib/layout'
import Banners from './Banners'
import { Settings } from './Settings'
import { DeactivationModules } from './DeactivationModules'
import { BlackList } from './BlackList'

const Routes = () => {
  const match = useRouteMatch()
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated)
  return isAuthenticated ? (
    <>
      <SectionMenu>
        <SectionMenuItem colorScheme="teal" to="/services/banners">
          Баннеры
        </SectionMenuItem>
        <SectionMenuItem colorScheme="teal" to="/services/settings">
          Настройки
        </SectionMenuItem>
        <SectionMenuItem colorScheme="teal" to="/services/deactivation-modules">
          Деактивация модулей
        </SectionMenuItem>
        <SectionMenuItem colorScheme="teal" to="/services/black-list">
          Черный список
        </SectionMenuItem>
      </SectionMenu>
      <Switch>
        <Route path={`${match.url}/banners`}>
          <Banners />
        </Route>
        <Route path={`${match.url}/settings`}>
          <Settings />
        </Route>
        <Route path={`${match.url}/deactivation-modules`}>
          <DeactivationModules />
        </Route>
        <Route path={`${match.url}/black-list`}>
          <BlackList />
        </Route>
      </Switch>
    </>
  ) : (
    <Login />
  )
}

export default Routes
