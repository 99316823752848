import React from 'react'
import { Box, Button, Text, useBoolean } from '@chakra-ui/react'
import useRemoveUser from './services/useRemoveUser'
import { Modal } from 'chakra-lib'

export const RemoveUserModal = ({ userId }) => {
  const { refetch, isLoading } = useRemoveUser(userId)
  const [isOpen, setOpenModal] = useBoolean()

  const handleSubmit = async () => {
    refetch()
    setOpenModal.off()
  }

  return (
    <>
      <Button width="100%" onClick={setOpenModal.on} colorScheme="red" isLoading={isLoading}>
        Заблокировать
      </Button>
      <Modal isOpen={isOpen} onClose={setOpenModal.off} title="Заблокировать пользователя">
        <Text fontSize="20px" color="red.500">
          Внимание!
        </Text>
        <Text>
          Данное действие нельзя будет отменить. Действие равносильно удалению аккаунта. При
          блокировке к телефону и email добавляется префикс _blocked.
        </Text>
        <Box pt="30px">
          <Button colorScheme="red" isLoading={isLoading} onClick={handleSubmit}>
            Заблокировать
          </Button>
        </Box>
      </Modal>
    </>
  )
}
