import React from 'react'
import { Button } from '@chakra-ui/react'
import useEnableUser from './services/useEnableUser'
import { getUserItem } from 'store/user.reducer'
import { useDispatch } from 'react-redux'

export const ActivateUser = ({ userId }) => {
  const dispatch = useDispatch()
  const { setEnable, isLoading } = useEnableUser(userId)

  const handleSubmit = async () => {
    await setEnable()
    await dispatch(getUserItem(userId))
  }

  return (
    <Button
      width="100%"
      onClick={handleSubmit}
      colorScheme="blue"
      variant="outline"
      isLoading={isLoading}
    >
      Активировать
    </Button>
  )
}
