import { api } from 'api'
import { getUserItem } from 'store/user.reducer'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import toast from 'utils/toast'

const useResetUserIdentification = (id) => {
  const dispatch = useDispatch()

  return useQuery('useResetUserIdentification', () => api.identifications.resetIdentification(id), {
    onError: (err) => {
      toast.showError({
        title: 'Произошла ошибка при сбросе идентификации',
        description: err.response?.data?.message || err.message,
      })
    },
    onSuccess: () => {
      dispatch(getUserItem(id))
    },
    enabled: false,
  })
}

export default useResetUserIdentification
